// source: https://github.com/andymantell/node-wpautop

/**
 * Convert plain text paragraphs into HTML paragraphs.
 *
 * @param {string} text - Plain text.
 * @param {boolean} [br = true] - Whether to convert all remaining line breaks after paragraphing (optional).
 * @returns {string} HTML.
 */

export const wpautop = (text, br = true) => {
  var preTags = {};
  if (text.trim() === "") {
    return "";
  }

  text = text + "\n"; // just to make things a little easier, pad the end
  if (text.indexOf("<pre") > -1) {
    var textParts = text.split("</pre>");
    var lastText = textParts.pop();
    text = "";
    textParts.forEach(function (textPart, index) {
      var start = textPart.indexOf("<pre");

      // Malformed html?
      if (start === -1) {
        text += textPart;
        return;
      }

      var name = "<pre wp-pre-tag-" + index + "></pre>";
      preTags[name] = textPart.substr(start) + "</pre>";
      text += textPart.substr(0, start) + name;
    });

    text += lastText;
  }

  text = text.replace(/<br \/>\s*<br \/>/, "\n\n");

  // Space things out a little
  var allblocks =
    "(?:table|thead|tfoot|caption|col|colgroup|tbody|tr|td|th|div|dl|dd|dt|ul|ol|li|pre|form|map|area|blockquote|address|math|style|p|h[1-6]|hr|fieldset|legend|section|article|aside|hgroup|header|footer|nav|figure|figcaption|details|menu|summary)";
  text = text.replace(new RegExp("(<" + allblocks + "[^>]*>)", "gmi"), "\n$1");
  text = text.replace(new RegExp("(</" + allblocks + ">)", "gmi"), "$1\n\n");
  text = text.replace(/\r\n|\r/, "\n"); // cross-platform newlines

  if (text.indexOf("<option") > -1) {
    // no P/BR around option
    text = text.replace(/\s*<option'/gim, "<option");
    text = text.replace(/<\/option>\s*/gim, "</option>");
  }

  if (text.indexOf("</object>") > -1) {
    // no P/BR around param and embed
    text = text.replace(/(<object[^>]*>)\s*/gim, "$1");
    text = text.replace(/\s*<\/object>/gim, "</object>");
    text = text.replace(/\s*(<\/?(?:param|embed)[^>]*>)\s*/gim, "$1");
  }

  if (text.indexOf("<source") > -1 || text.indexOf("<track") > -1) {
    // no P/BR around source and track
    // eslint-disable-next-line no-useless-escape
    text = text.replace(/([<\[](?:audio|video)[^>\]]*[>\]])\s*/gim, "$1");
    // eslint-disable-next-line no-useless-escape
    text = text.replace(/\s*([<\[]\/(?:audio|video)[>\]])/gim, "$1");
    text = text.replace(/\s*(<(?:source|track)[^>]*>)\s*/gim, "$1");
  }

  text = text.replace(/\n\n+/gim, "\n\n"); // take care of duplicates

  // make paragraphs, including one at the end
  var texts = text.split(/\n\s*\n/);
  text = "";
  texts.forEach(function (tinkle) {
    text += "<p>" + tinkle.replace(/^\s+|\s+$/g, "") + "</p>\n";
  });

  text = text.replace(/<p>\s*<\/p>/gim, ""); // under certain strange conditions it could create a P of entirely whitespace
  text = text.replace(/<p>([^<]+)<\/(div|address|form)>/gim, "<p>$1</p></$2>");
  text = text.replace(new RegExp("<p>s*(</?" + allblocks + "[^>]*>)s*</p>", "gmi"), "$1", text); // don't text all over a tag
  text = text.replace(/<p>(<li.+?)<\/p>/gim, "$1"); // problem with nested lists
  text = text.replace(/<p><blockquote([^>]*)>/gim, "<blockquote$1><p>");
  text = text.replace(/<\/blockquote><\/p>/gim, "</p></blockquote>");
  text = text.replace(new RegExp("<p>s*(</?" + allblocks + "[^>]*>)", "gmi"), "$1");
  text = text.replace(new RegExp("(</?" + allblocks + "[^>]*>)s*</p>", "gmi"), "$1");

  if (br) {
    text = text.replace(/<(script|style)(?:.|\n)*?<\/\\1>/gim, autopNewlinePreservationHelper); // /s modifier from php PCRE regexp replaced with (?:.|\n)
    text = text.replace(/(<br \/>)?\s*\n/gim, "<br />\n"); // optionally make line breaks
    text = text.replace("<WPPreserveNewline />", "\n");
  }

  text = text.replace(new RegExp("(</?" + allblocks + "[^>]*>)s*<br />", "gmi"), "$1");
  text = text.replace(/<br \/>(\s*<\/?(?:p|li|div|dl|dd|dt|th|pre|td|ul|ol)[^>]*>)/gim, "$1");
  text = text.replace(/\n<\/p>$/gim, "</p>");

  if (Object.keys(preTags).length) {
    text = text.replace(new RegExp(Object.keys(preTags).join("|"), "gi"), function (matched) {
      return preTags[matched];
    });
  }

  return text;
};

const autopNewlinePreservationHelper = (matches) => {
  return matches[0].replace("\n", "<WPPreserveNewline />");
};
