import { Link } from "react-router-dom";
import { useInfoPages, usePage, useSetCurrentBackground, useTheme } from "../../store/store";
import styles from "./Info.module.scss";
import { ReactComponent as Back } from "../../icons/nav.svg";
import { useEffect } from "react";

const Info = () => {
  const info = useInfoPages();
  const theme = useTheme();
  const setCurrentBackground = useSetCurrentBackground();

  useEffect(() => {
    if (theme) {
      setCurrentBackground(theme.json.default_background);
    }
  }, [setCurrentBackground, theme]);

  return (
    <div className={styles.wrapper} style={{ color: theme.json.colour_secondary }}>
      {info &&
        info.map((p) => {
          return (
            <Link key={p.identity} to={`/info/${p.identity}`}>
              <span>{p.nav_title ? p.nav_title : p.title}</span> <Back />
            </Link>
          );
        })}
    </div>
  );
};

export default Info;
