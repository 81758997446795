import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useCollectedItems, useItems, usePage, useTheme } from "../../store/store";
import { useMap } from "../../providers/MapProvider";
import useGeolocationAndOrientation from "../../hooks/useGeolocationAndOrientation";
import useEnvironmentDetection from "../../hooks/useEnvironmentDetection";
import { useAppContext } from "../../providers/AppProvider";
import Button from "../Button/Button";
import styles from "./Map.module.scss";
// import { throttle } from "lodash";
import clsx from "clsx";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmVua2VydmUiLCJhIjoiY2x2b2RhaDhzMGsydTJrbzFnZjdqdG9pcSJ9.2oB_BvYllcwr3Zd_0khzHA";

const Map = () => {
  const { geolocationSupported, orientationSupported } = useGeolocationAndOrientation();
  const {
    // ar,
    isMapExpanded,
    setIsMapExpanded,
    setShowCloseButton,
  } = useAppContext();

  const environment = useEnvironmentDetection();
  const items = useItems();
  const collectedItems = useCollectedItems();
  const theme = useTheme();
  const { markersRef } = useMap();
  const page = usePage("Game");

  const [userInteracted, setUserInteracted] = useState(false);
  // const [userBearing, setUserBearing] = useState(null);
  // const [userLocation, setUserLocation] = useState(null);
  // const [currentBubble, setCurrentBubble] = useState(null);

  const isMapExpandedRef = useRef(isMapExpanded);
  const userInteractedRef = useRef(false);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const popupsRef = useRef([]);
  const userBearingRef = useRef(null);
  const userLocationRef = useRef(null);
  const throttleRef1 = useRef(null);
  const throttleRef2 = useRef(null);

  // ============================================================
  // Dev - get orientation permissions on expand
  // ============================================================

  // const requestOrientationPermission = useCallback(() => {
  //   if (typeof DeviceOrientationEvent.requestPermission === "function") {
  //     DeviceOrientationEvent.requestPermission()
  //       .then((permissionState) => {
  //         if (permissionState === "granted") {
  //           console.log("Device orientation permission granted");
  //         }
  //       })
  //       .catch(console.error);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isMapExpanded) {
  //     requestOrientationPermission();
  //   }
  // }, [isMapExpanded, requestOrientationPermission]);

  // ============================================================
  // Device orientation handling
  // ============================================================

  const getAndroidBearing = useCallback((event) => {
    const { alpha, beta, gamma } = event;

    // Convert degrees to radians
    var alphaRad = alpha * (Math.PI / 180);
    var betaRad = beta * (Math.PI / 180);
    var gammaRad = gamma * (Math.PI / 180);

    // Calculate equation components
    var cA = Math.cos(alphaRad);
    var sA = Math.sin(alphaRad);
    // var cB = Math.cos(betaRad);
    var sB = Math.sin(betaRad);
    var cG = Math.cos(gammaRad);
    var sG = Math.sin(gammaRad);

    // Calculate A, B, C rotation components
    var rA = -cA * sG - sA * sB * cG;
    var rB = -sA * sG + cA * sB * cG;
    // var rC = -cB * cG;

    // Calculate compass heading
    var compassHeading = Math.atan(rA / rB);

    // Convert from half unit circle to whole unit circle
    if (rB < 0) {
      compassHeading += Math.PI;
    } else if (rA < 0) {
      compassHeading += 2 * Math.PI;
    }

    // Convert radians to degrees
    compassHeading *= 180 / Math.PI;

    return compassHeading;
  }, []);

  const handleDeviceOrientation = useCallback(
    (event) => {
      if (mapRef.current) {
        let newBearing;
        if (event.webkitCompassHeading) {
          newBearing = event.webkitCompassHeading;
        } else if (event.alpha != null) {
          // newBearing = Math.abs(event.alpha - 360);
          newBearing = getAndroidBearing(event);
          // const isInverted = event.beta > 90;
          // if (isInverted) {
          //   newBearing = (newBearing + 180) % 360;
          // }
        } else {
          newBearing = 0;
        }
        // setUserBearing(newBearing);
        userBearingRef.current = newBearing;
        mapRef.current.setBearing(newBearing);
      }
    },
    [getAndroidBearing]
  );

  const throttledHandleDeviceOrientation = useCallback(
    (event) => {
      if (throttleRef1.current !== null) {
        cancelAnimationFrame(throttleRef1.current);
      }
      throttleRef1.current = requestAnimationFrame(() => handleDeviceOrientation(event));
    },
    [handleDeviceOrientation]
  );

  const removeOrientationEventListeners = useCallback(() => {
    // console.log("REMOVE");
    window.removeEventListener("deviceorientationabsolute", throttledHandleDeviceOrientation);
    window.removeEventListener("deviceorientation", throttledHandleDeviceOrientation);
  }, [throttledHandleDeviceOrientation]);

  const addOrientationEventListeners = useCallback(() => {
    // console.log("ADD");
    removeOrientationEventListeners();
    if ("ondeviceorientationabsolute" in window) {
      window.addEventListener("deviceorientationabsolute", throttledHandleDeviceOrientation);
    } else {
      window.addEventListener("deviceorientation", throttledHandleDeviceOrientation);
    }
  }, [throttledHandleDeviceOrientation, removeOrientationEventListeners]);

  useEffect(() => {
    if (!isMapExpanded) {
      addOrientationEventListeners();
      return () => {
        removeOrientationEventListeners();
      };
    }
  }, [addOrientationEventListeners, removeOrientationEventListeners, isMapExpanded]);

  // ============================================================
  // Handle user interaction (pan, zoom etc)
  // ============================================================

  const handleUserInteraction = useCallback(() => {
    if (isMapExpanded) {
      removeOrientationEventListeners();
      userInteractedRef.current = true;
      setUserInteracted(true);
    }
  }, [removeOrientationEventListeners, isMapExpanded]);

  useEffect(() => {
    if (mapContainerRef.current) {
      const mapElement = mapContainerRef.current;

      if (isMapExpanded) {
        mapElement.addEventListener("click", handleUserInteraction);
        mapElement.addEventListener("touchstart", handleUserInteraction);
      } else {
        mapElement.removeEventListener("click", handleUserInteraction);
        mapElement.removeEventListener("touchstart", handleUserInteraction);
      }

      return () => {
        mapElement.removeEventListener("click", handleUserInteraction);
        mapElement.removeEventListener("touchstart", handleUserInteraction);
      };
    }
  }, [handleUserInteraction, isMapExpanded]);

  useEffect(() => {
    if (mapRef.current) {
      console.log("%c--- user activity ---", "color: #0080b2");
      const interactionState = isMapExpanded && userInteracted ? "enable" : "disable";
      mapRef.current.boxZoom[interactionState]();
      mapRef.current.scrollZoom[interactionState]();
      mapRef.current.dragPan[interactionState]();
      mapRef.current.dragRotate[interactionState]();
      mapRef.current.keyboard[interactionState]();
      mapRef.current.doubleClickZoom[interactionState]();
      mapRef.current.touchZoomRotate[interactionState]();
    }
  }, [isMapExpanded, userInteracted]);

  // ============================================================
  // Create and handle markers
  // ============================================================

  const calculateDistance = useCallback((lat1, lon1, lat2, lon2) => {
    const R = 6371000; // Radius of the Earth in meters
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * (1 - Math.cos(dLon))) /
        2;
    return R * 2 * Math.asin(Math.sqrt(a));
  }, []);

  const calculateBearing = useCallback((lat1, lon1, lat2, lon2) => {
    const lat1Rad = (lat1 * Math.PI) / 180;
    const lat2Rad = (lat2 * Math.PI) / 180;
    const deltaLonRad = ((lon2 - lon1) * Math.PI) / 180;

    const y = Math.sin(deltaLonRad) * Math.cos(lat2Rad);
    const x =
      Math.cos(lat1Rad) * Math.sin(lat2Rad) -
      Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLonRad);
    let bearing = Math.atan2(y, x) * (180 / Math.PI);
    bearing = (bearing + 360) % 360; // Normalize to 0-360
    return bearing;
  }, []);

  const getMarkerStyles = useCallback(() => {
    console.log("%c--- getMarkerStyles ---", "color: #0080b2");
    return {
      width: isMapExpanded ? "26px" : "10px",
      height: isMapExpanded ? "36px" : "10px",
      marginTop: isMapExpanded ? "-18px" : "-5px",
      marginLeft: "3px", // isMapExpanded ? "-13px" : "-5px",
      borderRadius: isMapExpanded ? "0" : "50%",
      background: isMapExpanded
        ? `transparent url('${theme.placemarker_image.url}') center no-repeat`
        : "#7D7D7D",
      backgroundSize: "cover",
    };
  }, [isMapExpanded, theme.placemarker_image.url]);

  const generatePopupContent = useCallback(
    (userLocation, markerLocation, title) => {
      const distance = userLocation
        ? Math.round(
            calculateDistance(
              userLocation[1],
              userLocation[0],
              markerLocation.lat,
              markerLocation.lng
            )
          )
        : null;

      const displayBearing = environment !== "production";

      const bearing =
        displayBearing && userLocation
          ? calculateBearing(
              userLocation[1],
              userLocation[0],
              markerLocation.lat,
              markerLocation.lng
            )
          : null;

      const distanceContent = distance ? `<h3>${distance}m</h3>` : "";
      const bearingContent = bearing ? `<p>${bearing.toFixed(2)}°</p>` : "";
      const titleContent = title ? `<p>${title}</p>` : "";
      return distanceContent + bearingContent + titleContent;
    },
    [calculateDistance, calculateBearing, environment]
  );

  const addMarkers = useCallback(
    (map, products, collectedItems) => {
      console.log("%c--- addMarkers ---", "color: #0080b2");
      let markersAdded = 0;
      const totalMarkers = products.filter(
        (product) => !collectedItems.includes(product.identity)
      ).length;

      const markerStyles = getMarkerStyles();
      products.forEach((product) => {
        if (!collectedItems.includes(product.identity)) {
          const markerElement = document.createElement("div");
          markerElement.style.width = markerStyles.width;
          markerElement.style.height = markerStyles.height;
          markerElement.style.marginTop = markerStyles.marginTop;
          markerElement.style.marginLeft = markerStyles.marginLeft;
          markerElement.style.borderRadius = markerStyles.borderRadius;
          markerElement.style.background = markerStyles.background;
          markerElement.style.backgroundSize = markerStyles.backgroundSize;

          markerElement.dataset.id = product.identity;
          markerElement.dataset.title = product.description;

          const marker = new mapboxgl.Marker(markerElement)
            .setLngLat([product.geo_lon, product.geo_lat])
            .addTo(map);

          const popupContent = generatePopupContent(
            userLocationRef.current,
            { lat: product.geo_lat, lng: product.geo_lon },
            product.description
          );

          const popup = new mapboxgl.Popup().setHTML(popupContent);
          popupsRef.current.push(popup);
          marker.setPopup(popup);

          markersRef.current[product.identity] = marker;

          markersAdded++;
          if (markersAdded === totalMarkers) {
            fitMapToBounds();
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [markersRef, isMapExpanded, generatePopupContent]
  );

  const closeAllPopups = useCallback(() => {
    console.log("%c--- closeAllPopups ---", "color: #0080b2");
    popupsRef.current.forEach((popup) => {
      popup.remove();
    });
  }, []);

  // Update marker distances

  const updateMarkerDistances = useCallback(() => {
    console.log("%c--- updateMarkerDistances ---", "color: #0080b2");
    Object.values(markersRef.current).forEach((marker) => {
      const markerLngLat = marker.getLngLat();

      const popupContent = generatePopupContent(
        userLocationRef.current,
        markerLngLat,
        marker.getElement().dataset.title
      );

      marker.getPopup().setHTML(popupContent);
    });
  }, [markersRef, userLocationRef, generatePopupContent]);

  // Update marker styles on map expand/collapse

  const updateMarkerStyles = useCallback(() => {
    console.log("%c--- updateMarkerStyles ---", "color: #0080b2");
    const markerStyles = getMarkerStyles();
    Object.values(markersRef.current).forEach((marker) => {
      const markerElement = marker.getElement();
      markerElement.style.width = markerStyles.width;
      markerElement.style.height = markerStyles.height;
      markerElement.style.marginTop = markerStyles.marginTop;
      markerElement.style.marginLeft = markerStyles.marginLeft;
      markerElement.style.borderRadius = markerStyles.borderRadius;
      markerElement.style.background = markerStyles.background;
      markerElement.style.backgroundSize = markerStyles.backgroundSize;
    });
  }, [markersRef, getMarkerStyles]);

  useEffect(() => {
    updateMarkerStyles();
  }, [isMapExpanded, updateMarkerStyles]);

  // Update marker distances on interval

  useEffect(() => {
    const markerDistanceInterval = setInterval(() => {
      if (isMapExpandedRef.current) {
        updateMarkerDistances();
      }
    }, 5000);

    // cleanup on dismount
    return () => {
      clearInterval(markerDistanceInterval);
    };
  }, [updateMarkerDistances]);

  // ============================================================
  // DEVELOPER - update and display marker distances from user
  // ============================================================

  // const getMarkerBearingFromUser = useCallback(
  //   (userBearing, userLocation, markerLngLat) => {
  //     const markerBearing =
  //       userLocation && markerLngLat
  //         ? calculateBearing(userLocation[1], userLocation[0], markerLngLat.lat, markerLngLat.lng)
  //         : null;
  //     const myBearing = userBearing ? userBearing : 0;
  //     let relativeBearing = markerBearing - (myBearing || 0) + 180;
  //     if (relativeBearing < 0) {
  //       relativeBearing += 360;
  //     } else if (relativeBearing > 360) {
  //       relativeBearing -= 360;
  //     }
  //     return relativeBearing;
  //   },
  //   [calculateBearing]
  // );

  // const showBubbleOriginal = useCallback(
  //   (markerId) => {
  //     // alert(markerId);
  //     try {
  //       ar.current.showBubble(markerId);
  //     } catch (e) {}
  //   },
  //   [ar]
  // );

  // const showBubble = useMemo(() => throttle(showBubbleOriginal, 4000), [showBubbleOriginal]);

  // const checkBubbleDistances = useCallback(() => {
  //   const minDistance = 5;
  //   const minAngle = 10;

  //   let closestMarker = null;
  //   let closestDistance = null;
  //   // let markersInView = 0;

  //   // loop through all markers
  //   Object.values(markersRef.current).forEach((marker) => {
  //     // get the marker angle from the user
  //     const markerLngLat = marker.getLngLat();
  //     const bearingFromUser = getMarkerBearingFromUser(
  //       userBearingRef.current,
  //       userLocationRef.current,
  //       markerLngLat
  //     );
  //     const angleFromUser = Math.abs(bearingFromUser - 180);

  //     // if in view, check the distance
  //     if (angleFromUser < minAngle) {
  //       // markersInView++;
  //       const distance = calculateDistance(
  //         userLocationRef.current[1],
  //         userLocationRef.current[0],
  //         markerLngLat.lat,
  //         markerLngLat.lng
  //       );
  //       if (closestDistance === null || distance < closestDistance) {
  //         closestDistance = distance;
  //         if (distance < minDistance) {
  //           // determine the closest marker
  //           closestMarker = marker.getElement().dataset.id;
  //         }
  //       }
  //     }
  //   });

  //   // console.log(markersInView);
  //   // console.log(closestDistance);
  //   if (closestMarker) {
  //     // console.log(closestMarker);
  //     showBubble(closestMarker);
  //     setCurrentBubble(closestMarker);
  //   } else {
  //     setCurrentBubble(null);
  //   }
  // }, [calculateDistance, getMarkerBearingFromUser, showBubble, markersRef]);

  // Run on interval

  // useEffect(() => {
  //   const bubbleDistanceInterval = setInterval(() => {
  //     checkBubbleDistances();
  //   }, 500);

  //   // cleanup on dismount
  //   return () => {
  //     clearInterval(bubbleDistanceInterval);
  //   };
  // }, [checkBubbleDistances]);

  // ============================================================
  // Fit map to bounds
  // ============================================================

  const fitMapToBoundsOriginal = useCallback(() => {
    if (mapRef.current) {
      console.log("%c--- fitMapToBounds ---", "color: #f67ceb");
      mapRef.current.resize();
      removeOrientationEventListeners();
      let bounds = new mapboxgl.LngLatBounds();
      let markerCount = 0;

      // Loop through markers and extend bounds
      Object.values(markersRef.current).forEach((marker) => {
        const lngLat = marker.getLngLat();
        if (lngLat && lngLat.lng !== undefined && lngLat.lat !== undefined) {
          bounds.extend(lngLat);
          markerCount++;
        }
      });

      // Get the user's location, with a fallback to the bounds center
      let boundsCenter;
      try {
        boundsCenter = bounds.getCenter();
      } catch (e) {}
      const centerLat = userLocationRef.current?.[1] || boundsCenter?.lat || null;
      const centerLng = userLocationRef.current?.[0] || boundsCenter?.lng || null;

      // Extend bounds to include user's current location
      if (centerLat) {
        bounds.extend({
          lng: centerLng,
          lat: centerLat,
        });
      }

      if (centerLat && markerCount > 0) {
        // Get the distance of the furthest marker from the center
        const maxDistanceMetres = Math.ceil(
          Math.max(
            ...Object.values(markersRef.current).map((marker) => {
              const lngLat = marker.getLngLat();
              return lngLat ? calculateDistance(centerLat, centerLng, lngLat.lat, lngLat.lng) : 0;
            })
          )
        );
        const metersPerDegreeLatitude = 111320; // 1 degree of latitude is ~111320m
        const metersPerDegreeLongitude =
          Math.cos((centerLat * Math.PI) / 180) * metersPerDegreeLatitude;

        const maxLatDistance = maxDistanceMetres / metersPerDegreeLatitude;
        const maxLngDistance = maxDistanceMetres / metersPerDegreeLongitude;

        // Create bounds based on the furthest marker from the center
        const currentLocation = new mapboxgl.LngLat(centerLng, centerLat);
        bounds = new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(
            currentLocation.lng - maxLngDistance,
            currentLocation.lat - maxLatDistance
          ),
          new mapboxgl.LngLat(
            currentLocation.lng + maxLngDistance,
            currentLocation.lat + maxLatDistance
          )
        );
      }

      // // Adjust bounds to center on user's location (OLD)
      // if (centerLat && markerCount > 0) {
      //   const currentLocation = new mapboxgl.LngLat(centerLng, centerLat);
      //   const sw = bounds.getSouthWest();
      //   const ne = bounds.getNorthEast();
      //   const maxLngDistance = Math.max(
      //     Math.abs(currentLocation.lng - sw.lng),
      //     Math.abs(currentLocation.lng - ne.lng)
      //   );
      //   const maxLatDistance = Math.max(
      //     Math.abs(currentLocation.lat - sw.lat),
      //     Math.abs(currentLocation.lat - ne.lat)
      //   );
      //   const maxDistance = Math.max(maxLngDistance, maxLatDistance);
      //   bounds = new mapboxgl.LngLatBounds(
      //     new mapboxgl.LngLat(currentLocation.lng - maxDistance, currentLocation.lat - maxDistance),
      //     new mapboxgl.LngLat(currentLocation.lng + maxDistance, currentLocation.lat + maxDistance)
      //   );
      // }

      // Apply bounds to map
      if (mapRef.current && markerCount > 0 && bounds) {
        mapRef.current.fitBounds(bounds, {
          padding: isMapExpandedRef.current ? 20 : 15,
          pitch: isMapExpandedRef.current ? 50 : 0,
          animate: false,
        });
      }
      addOrientationEventListeners();
    }
  }, [
    addOrientationEventListeners,
    removeOrientationEventListeners,
    isMapExpandedRef,
    markersRef,
    userLocationRef,
    calculateDistance,
  ]);

  // const fitMapToBounds = throttle(fitMapToBoundsOriginal, 150);

  const fitMapToBounds = useCallback(() => {
    if (throttleRef2.current !== null) {
      cancelAnimationFrame(throttleRef2.current);
    }
    throttleRef2.current = requestAnimationFrame(() => fitMapToBoundsOriginal());
  }, [fitMapToBoundsOriginal]);

  // ============================================================
  // Center the map
  // ============================================================

  // Manually center the map

  const toggleCenter = useCallback(
    (centered) => {
      console.log("%c--- toggleCenter ---", "color: #0080b2");
      if (centered) {
        removeOrientationEventListeners();
        userInteractedRef.current = true;
        setUserInteracted(true);
      } else {
        fitMapToBounds();
        // mapRef.current.setCenter(userLocationRef.current);
        addOrientationEventListeners();
        userInteractedRef.current = false;
        setUserInteracted(false);
      }
    },
    [addOrientationEventListeners, removeOrientationEventListeners, fitMapToBounds]
  );

  // Keep the map centered as the user's location updates

  const centerMap = useCallback(() => {
    // console.log(userInteractedRef.current);
    if (!userInteractedRef.current && mapRef.current && userLocationRef.current) {
      // console.log("%c--- centerMap ---", "color: #0080b2");
      mapRef.current.setCenter(userLocationRef.current);
    }
  }, [userLocationRef, userInteractedRef]);

  // ============================================================
  // Handle map expand/collapse
  // ============================================================

  const handleMapExpandClick = () => {
    if (!isMapExpanded) {
      setIsMapExpanded(true);
    }
  };

  useEffect(() => {
    if (!isMapExpanded) {
      userInteractedRef.current = false;
      setUserInteracted(false);
    }
  }, [isMapExpanded]);

  useEffect(() => {
    isMapExpandedRef.current = isMapExpanded;
    if (isMapExpanded) {
      setShowCloseButton(true);
      updateMarkerDistances();
    } else {
      setShowCloseButton(false);
      closeAllPopups();
    }
  }, [isMapExpanded, updateMarkerDistances, closeAllPopups, setShowCloseButton]);

  // ============================================================
  // Initialise mapbox
  // ============================================================

  useEffect(() => {
    console.log("%c*** init map ***", "color: #2bc600");

    // create a new map
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/benkerve/clwhsi5q4003301poa3ckgfkh",
      center: [0, 0],
      zoom: 11,
      pitch: 0,
      interactive: !isMapExpanded,
      attributionControl: false,
    });
    mapRef.current = map;

    // add geolocate control
    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      showUserHeading: true,
      trackUserLocation: true,
      fitBoundsOptions: { maxZoom: 18 },
    });
    map.addControl(geolocateControl);
    map.on("load", () => {
      console.log("%c--- load ---", "color: #c67000");
      addMarkers(map, items, collectedItems);
      geolocateControl.trigger();
    });

    // set initial location and heading
    geolocateControl.once("geolocate", (e) => {
      console.log("%c--- geolocate once ---", "color: #c3c600");
      const { longitude, latitude } = e.coords;
      map.setCenter([longitude, latitude]);
      userLocationRef.current = [longitude, latitude];
      // setUserLocation([longitude, latitude]);
      rotateMapToInitialHeading(map, e.coords.heading);
      fitMapToBounds();
    });

    // update user location on geolocate
    geolocateControl.on("geolocate", (e) => {
      console.log("%c--- geolocate ---", "color: #c3c600");
      const { longitude, latitude } = e.coords;
      userLocationRef.current = [longitude, latitude];
      // setUserLocation([longitude, latitude]);
      centerMap();
    });

    // update map heading on geolocate heading
    geolocateControl.on("heading", (e) => {
      console.log("%c--- heading ---", "color: #c3c600");
      if (mapRef.current && e?.target?.heading !== null) {
        mapRef.current.setBearing(e.target.heading);
      }
    });

    // update map heading on device orientation
    const rotateMapToInitialHeading = (map, initialHeading) => {
      const mapHeading = 360 - initialHeading;
      map.rotateTo(mapHeading, { duration: 0 });
    };

    // fit map to bounds on resize
    const resizeObserver = new ResizeObserver(fitMapToBounds);
    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current);
    }

    // cleanup
    return () => {
      if (mapRef.current) mapRef.current.remove();
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // items, isMapExpanded, fitMapToBounds, collectedItems, addMarkers

  // ============================================================
  // Render component
  // ============================================================

  return (
    <>
      <div className={clsx(styles.wrapper, { [styles.wrapperExpanded]: isMapExpanded })}>
        <div
          ref={mapContainerRef}
          style={{
            top: "0",
            left: "0",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        ></div>
        {/* CENTER MAP BUTTON */}
        {isMapExpanded && (
          <div className={styles.buttonCenterWrapper}>
            <Button
              content={{
                body: page.center_button_text ? page.center_button_text : "Center",
                backgroundColor: userInteracted ? "#1b1b1b" : "#aaaaaa",
                textColor: "#fff",
              }}
              onClick={() => toggleCenter(!userInteracted)}
            />
          </div>
        )}

        {isMapExpanded && !geolocationSupported && (
          <div className={styles.geoError}>
            <span>Ihr Standort ist derzeit nicht verfügbar</span>
          </div>
        )}
        {/* DEVELOPER LOGS */}
        {/* {environment !== "production" && (
          <div className={styles.debug}>
            {userBearing && `Bearing: ${userBearing.toFixed(2)}°`}
            <br />
            {userLocation && `Lon: ${userLocation[0].toFixed(8)}`}
            <br />
            {userLocation && `Lat: ${userLocation[1].toFixed(8)}`}
          </div>
        )} */}
        {/* EXPANDMAP BUTTON */}
        {!isMapExpanded && (
          <div
            style={{ height: "100%", width: "100%", position: "absolute", zIndex: "9999999" }}
            onClick={handleMapExpandClick}
          ></div>
        )}
      </div>
      {/* DEVELOPER COMPASS VIEW */}
      {/* {environment !== "production" && (
        <>
          <MapCompass
            markersRef={markersRef}
            userLocation={userLocation}
            userBearing={userBearing}
            calculateDistance={calculateDistance}
            getMarkerBearingFromUser={getMarkerBearingFromUser}
          />
          {currentBubble && <div className={styles.currentBubble}>{currentBubble}</div>}
        </>
      )} */}
    </>
  );
};

// DEVELOPER COMPASS VIEW
// const MapCompass = ({
//   markersRef,
//   userLocation,
//   userBearing,
//   calculateDistance,
//   getMarkerBearingFromUser,
// }) => {
//   return (
//     <div className={styles.compass}>
//       <div className={styles.compassCenter}></div>
//       {Object.values(markersRef.current).map((marker) => {
//         const markerId = marker.getElement().dataset.id;
//         const markerLngLat = marker.getLngLat();

//         // bearing maths
//         const bearingFromUser = getMarkerBearingFromUser(userBearing, userLocation, markerLngLat);
//         const bearingToPercent = (bearingFromUser / 360) * 100;

//         // distance maths
//         const markerDistMax = 60;
//         const markerDistMin = 10;
//         let markerDistance = markerLngLat
//           ? Math.round(
//               calculateDistance(
//                 userLocation && userLocation[1],
//                 userLocation && userLocation[0],
//                 markerLngLat.lat,
//                 markerLngLat.lng
//               )
//             ) - markerDistMin
//           : null;
//         if (markerDistance > markerDistMax) {
//           markerDistance = markerDistMax;
//         } else if (markerDistance < 0) {
//           markerDistance = 0;
//         }
//         markerDistance = markerDistMax - markerDistance;
//         const markerDistanceToDecimal = markerDistance / markerDistMax;
//         const markerDistanceToScale = markerDistanceToDecimal * 0.6 + 0.4;

//         // generate a color based on distance, red to green
//         const hue = (markerDistance / markerDistMax) * 120;

//         return (
//           <div
//             key={markerId}
//             className={styles.compassMarker}
//             style={{
//               left: bearingToPercent + "%",
//               transform: `scale(${markerDistanceToScale}) translateX(-50%)`,
//               backgroundColor: `hsl(${hue}, 100%, 50%)`,
//             }}
//           ></div>
//         );
//       })}
//     </div>
//   );
// };

export default Map;
