import React from "react";
import styles from "./bubble.module.scss";
import { useItems, useTheme } from "../../store/store";

const Bubble = ({ callback, bubble, animationConfig, animationStart }) => {
  const theme = useTheme();
  const items = useItems();

  const getContent = () => {
    if (bubble.content.type === "image") {
      return <img className={styles.innerImg} src={bubble.content.content} alt="bubble" />;
    }

    if (bubble.content.type === "free_text") {
      return (
        <div
          className={`${styles.freeText} ${styles[bubble.content.style]}`}
          dangerouslySetInnerHTML={{ __html: bubble.content.content }}
        ></div>
      );
    }

    if (bubble.content.type === "count" || bubble.content.type === "multi_field") {
      return (
        <div className={`${styles.multi}`}>
          <div className={styles.field1}>{bubble.content.field_1}</div>
          <div className={bubble.content.type === "count" ? styles.count : styles.field2}>
            {bubble.content.type === "count" ? `${items.length}x` : bubble.content.field_2}
          </div>
          <div className={styles.field3}>{bubble.content.field_3}</div>
        </div>
      );
    }
  };

  return (
    <div onClick={() => callback && callback()} className={styles.bubbleWrapper}>
      <img
        className={`${styles.bubble} ${styles.image1}`}
        src={bubble.image}
        alt="bubble2"
        style={{
          opacity: theme.json.bubble_layer_one_opacity,
        }}
      />
      <img
        className={`${styles.bubble} ${styles.image2}`}
        src={bubble.image}
        alt="bubble2"
        style={{
          opacity: theme.json.bubble_layer_two_opacity,
        }}
      />
      <div className={styles.content}>{getContent()}</div>
    </div>
  );
};

export default Bubble;
