import { NavLink, useParams } from "react-router-dom";

import Wysiwyg from "../Wysiwyg/Wysiwyg";
import errors from "../../Pages/App/errors_de.json";
import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
  const { errorPage } = useParams();

  // Location permissions denied

  if (errorPage === "location") {
    return (
      <>
        <div className={styles.bg}></div>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <img src="/assets/location.png" />
          </div>
          <Wysiwyg wpautop content={errors.locationError.title} />
          {deviceIsIos() && (
            <NavLink className={styles.button} to={"/error/location-ios"}>
              {errors.locationError.iosButton}
            </NavLink>
          )}
        </div>
      </>
    );
  }

  // Location permissions denied

  if (errorPage === "ar") {
    return (
      <>
        <div className={styles.bg}></div>
        <div className={styles.wrapper}>
          <Wysiwyg wpautop content={errors.arError.message} />
        </div>
      </>
    );
  }

  // Location permissions iOS help

  if (errorPage === "location-ios") {
    return (
      <>
        <div className={styles.bg}></div>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <img src="/assets/location.png" />
          </div>
          <div className={styles.leftAlign}>
            <ul>
              <li>
                Open the <strong>Settings</strong> app.
              </li>
              <li>
                Tap <strong>Privacy &amp; Security</strong>.
              </li>
              <li>
                Tap <strong>Location Services</strong>.
              </li>
              <li>Ensure that location services are enabled for your web browser.</li>
            </ul>
            <p>If that doesn't work:</p>
            <ul>
              <li>
                Open the <strong>Settings</strong> app.
              </li>
              <li>
                Tap <strong>General</strong>.
              </li>
              <li>
                Tap <strong>Transfer or Reset iPhone</strong>.
              </li>
              <li>
                Tap <strong>Reset</strong>.
              </li>
              <li>
                Tap <strong>Reset Location &amp; Privacy</strong>.
              </li>
              <li>Enter your passcode to continue.</li>
            </ul>
          </div>
          <NavLink className={styles.button} to={"/error/location"}>
            {errors.locationErrorIOS.backButton}
          </NavLink>
        </div>
      </>
    );
  }

  // 404 error
  else {
    return (
      <>
        <div className={styles.bg}></div>
        <div className={styles.wrapper}>
          <h1>404 Not Found</h1>
        </div>
      </>
    );
  }
};

const deviceIsIos = () => {
  return navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
};

export default ErrorPage;
