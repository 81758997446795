// Returns a random number between min (inclusive) and max (exclusive)
export const getRandomArbitrary = (min, max, floor = false) => {
  let result;
  if (floor) {
    result = Math.floor(Math.random() * (max - min) + min);
  } else {
    result = Math.random() * (max - min) + min;
  }
  return result;
};
