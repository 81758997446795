import {
  // useNavigate,
  useParams,
} from "react-router-dom";
import styles from "./InfoPage.module.scss";
import {
  useBackground,
  useInfoPages,
  // usePage,
  useSetCurrentBackground,
  useTheme,
} from "../../store/store";
import Button from "../Button/Button";
import Wysiwyg from "../Wysiwyg/Wysiwyg";
import { useEffect } from "react";

const InfoPage = () => {
  let { id } = useParams();
  const info = useInfoPages();
  // const navigate = useNavigate();
  const theme = useTheme();
  const background = useBackground();

  const page = info.find((i) => i.identity === id);

  const setCurrentBackground = useSetCurrentBackground();

  useEffect(() => {
    if (theme) {
      setCurrentBackground(theme.json.default_background);
    }
  }, [setCurrentBackground, theme]);

  console.log(page);
  return (
    <div
      className={styles.wrapper}
      style={{
        color:
          background.type === "image"
            ? theme.json.text_color_background
            : theme.json.colour_secondary,
      }}
    >
      <h1 className={styles.title}>
        <Wysiwyg content={page.title} nl2br />
      </h1>
      <h3 className={styles.intro}>
        <Wysiwyg content={page.intro} nl2br />
      </h3>
      <div className={styles.body} style={{ textAlign: page.body_align }}>
        <Wysiwyg content={page.body} />
      </div>

      {page.button_label && page.button_link && (
        <div className={styles.button}>
          <Button
            onClick={() => window.open(page.button_link, "_blank")}
            content={{
              type: "fill",
              // href: page.button.href, // This is not used anymore since we handle routing in onClick
              body: page.button_label,
              backgroundColor: theme.json.colour_secondary,
              borderColor: theme.json.colour_secondary,
              textColor: theme.json.colour_primary,
              maxWidth: "180px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default InfoPage;
