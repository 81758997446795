import {
  // useInfoPages,
  // usePage,
  useTheme,
} from "../../store/store";
import styles from "./OrientationMessage.module.scss";
import errors from "../../Pages/App/errors_de.json";

const OrientationMessage = () => {
  const theme = useTheme();
  return (
    <div className={styles.wrapper} style={{ color: theme.json.colour_secondary }}>
      <p>{errors.orientationError.title}</p>
    </div>
  );
};

export default OrientationMessage;
