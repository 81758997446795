import styles from "./Win.module.scss";

const Win = () => {
  return (
    <div>
      <h1>Win</h1>
    </div>
  );
};

export default Win;
