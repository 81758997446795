import { useNavigate } from "react-router-dom";
import {
  useCollectedItems,
  useItems,
  usePage,
  useSetCurrentBackground,
  useTheme,
} from "../../store/store";
import styles from "./CollectedItems.module.scss";
import { useEffect } from "react";

const CollectedItems = () => {
  const items = useItems();
  const collectedItems = useCollectedItems();
  const navigate = useNavigate();
  const theme = useTheme();
  const setCurrentBackground = useSetCurrentBackground();
  const page = usePage("collected_it3ms");

  const backgroundType = page.background.type;

  useEffect(() => {
    if (page) {
      setCurrentBackground(page.background);
    }
  }, [page, setCurrentBackground]);

  const handleClick = (collected, id) => {
    if (collected) {
      navigate(`/item/${id}`);
    }
  };

  const outline = () => {
    if (backgroundType === "image") {
      return theme.json.icon_border_background;
    } else {
      return theme.json.icon_border_solid;
    }
  };

  const textColor = () => {
    if (backgroundType === "image") {
      return theme.json.text_color_background;
    } else {
      return theme.json.colour_secondary;
    }
  };

  return (
    <div className={styles.wrapper} style={{ color: textColor() }}>
      <h1>{collectedItems.length === items.length ? page.win_title : page.title}</h1>
      <p>{page.body.replace("{{count}}", items.length)}</p>
      <div className={styles.grid}>
        {items.map((item) => (
          <div
            key={item.identity}
            onClick={() => handleClick(collectedItems.includes(item.identity), item.identity)}
          >
            <div
              className={`${styles.item} ${
                collectedItems.includes(item.identity) ? styles.collected : ""
              }`}
              style={
                collectedItems.includes(item.identity)
                  ? {
                      backgroundColor: item.item_page_bgcolor_start,
                      outlineColor: outline(),
                    }
                  : {
                      outlineColor: outline(),
                    }
              }
            >
              {collectedItems.includes(item.identity) && (
                <img className={styles.innerItem} alt="product" src={item.item_thumbnail.url}></img>
              )}
            </div>
            <h4>
              {collectedItems.includes(item.identity) ? item.item_title : page.not_found_text}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollectedItems;
