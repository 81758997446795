import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Item.module.scss";
import { useItems, useSetCurrentBackground, useTheme } from "../../store/store";
import { useEffect } from "react";
import Button from "../Button/Button";
import ScrollPrompt from "../ScrollPrompt/ScrollPrompt";
import Wysiwyg from "../Wysiwyg/Wysiwyg";

const Item = () => {
  const navigate = useNavigate();
  const items = useItems();
  let { id } = useParams();
  const setCurrentBackground = useSetCurrentBackground();
  const page = items.find((i) => i.identity === id);
  const theme = useTheme();

  console.log(page);

  useEffect(() => {
    setCurrentBackground({
      type: "color",
      value: page.item_page_bgcolor_start,
    });
  }, [page.item_page_bgcolor_end, page.item_page_bgcolor_start, setCurrentBackground]);

  const confirmContent = {
    type: "fill",
    href: "#", // This is not used anymore since we handle routing in onClick
    body: page.item_button_label,
    backgroundColor: theme.json.colour_secondary,
    borderColor: theme.json.colour_secondary,
    textColor: theme.json.colour_primary,
    maxWidth: "180px",
  };

  return (
    <>
      <div
        style={{
          background: `linear-gradient(to top, ${page.item_page_bgcolor_start} 0%, ${page.item_page_bgcolor_end} 100%)`,
        }}
        className={clsx(styles.gradientWrap)}
      ></div>
      <div
        className={clsx(styles.wrapper, "product-wrapper")}
        style={{ color: theme.json.colour_secondary }}
      >
        <img
          className={styles.logo}
          src={theme.logo_image.url}
          alt="product"
          width="163"
          height="39"
        />

        <img
          className={styles.product}
          src={page.item_image.url}
          alt="product"
          width="600"
          height="600"
        />
        <ScrollPrompt className={"product-scrollprompt"} />
        <h1 className={styles.title}>
          <Wysiwyg content={page.item_title} nl2br />
        </h1>
        <span className={styles.subtitle}>
          <Wysiwyg content={page.item_subtitle} nl2br />
        </span>
        <h3 className={styles.intro}>
          <Wysiwyg content={page.item_intro} nl2br />
        </h3>
        {page.item_body1 && (
          <div className={styles.body}>
            <Wysiwyg content={page.item_body1} wpautop />
          </div>
        )}
        {page.item_body2 && (
          <div className={styles.body}>
            <Wysiwyg content={page.item_body2} wpautop />
          </div>
        )}
        {page.item_footnote && (
          <div className={styles.footnote}>
            <Wysiwyg content={page.item_footnote} wpautop />
          </div>
        )}

        <Button content={confirmContent} onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Item;
