import { useEffect, useState } from "react";
import { useTheme } from "../../store/store";
import errors from "../../Pages/App/errors_de.json";
import styles from "./OfflineMessage.module.scss";

const OfflineMessage = () => {
  const theme = useTheme();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    const checkConnection = async () => {
      try {
        const response = await fetch("https://collect-and-win.vercel.app/favicon.ico", {
          method: "HEAD",
        });
        setIsOnline(response.ok);
      } catch (error) {
        setIsOnline(false);
      }
    };
    const interval = setInterval(checkConnection, 5000);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(interval);
    };
  }, []);

  if (!isOnline) {
    return (
      <div className={styles.wrapper} style={{ color: theme.json.colour_secondary }}>
        <h1>{errors.offlineError.title}</h1>
        <p>{errors.offlineError.message}</p>
      </div>
    );
  }
};

export default OfflineMessage;
