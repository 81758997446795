// AgeScreener.js
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useEvent, useGame, usePage, useTheme } from "../../store/store";
import styles from "./AgeScreener.module.scss";
import Button from "../Button/Button";
import Bubble from "../Bubble/Bubble";
import errors from "../../Pages/App/errors_de.json";

gsap.registerPlugin(useGSAP);

const AgeScreener = () => {
  const navigate = useNavigate();
  const page = usePage("AgeScreener");
  const theme = useTheme();
  const modalRef = useRef(null);

  const game = useGame();
  const event = useEvent();

  useGSAP(
    () => {
      const config = {
        scale: 1,
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
      };

      return gsap.fromTo(modalRef.current, { opacity: 0, scale: 1.05 }, config);
    },
    { scope: modalRef }
  );

  const handleOver18Click = () => {
    gsap.to(modalRef.current, {
      opacity: 0,
      duration: 0.5,
      ease: "power3.in",
      onComplete: () => navigate("/instructions"),
    });
  };

  const confirmContent = {
    type: "fill",
    href: "#", // This is not used anymore since we handle routing in onClick
    body: page.buttons.over18,
    backgroundColor: theme.json.colour_tertiary,
    borderColor: theme.json.colour_tertiary,
    textColor: theme.json.colour_primary,
    maxWidth: "100%",
  };

  const declineContent = {
    type: "border",
    href: "/underage",
    body: page.buttons.under18,
    backgroundColor: "transparent",
    borderColor: theme.json.colour_tertiary,
    textColor: theme.json.colour_primary,
    maxWidth: "100%",
  };

  const bubbleContent = {
    type: "image",
    content: theme.bubbles_logo_image ? theme.bubbles_logo_image.url : "",
  };

  const isGameFinished = () => {
    const givenDateString = event.end_date;

    const givenDate = new Date(givenDateString);

    const currentDate = new Date();

    const givenDateOnly = new Date(
      givenDate.getFullYear(),
      givenDate.getMonth(),
      givenDate.getDate()
    );
    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (game.claimed || givenDateOnly < currentDateOnly) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div ref={modalRef} className={styles.wrapper}>
        {isGameFinished() && (
          <h1>{errors.eventEndedError.title + " " + errors.eventEndedError.message}</h1>
        )}

        {!isGameFinished() && (
          <>
            <h1>{page.title}</h1>
            <p>{page.info}</p>
            <div className={styles.buttonContainer}>
              <Button content={confirmContent} onClick={handleOver18Click} />
              <Button content={declineContent} onClick={() => navigate("/underage")} />
            </div>
          </>
        )}
      </div>
      <div className={styles.bubbleWrapper}>
        <div className={styles.bubbleContainer}>
          <Bubble bubble={{ image: theme.bubbles_image.url, content: bubbleContent }} />
        </div>
      </div>
    </>
  );
};

export default AgeScreener;
