import { create } from "zustand";
import axios from "axios";
import { getEnvironment } from "../hooks/useEnvironmentDetection";

const API_HEADERS = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
  "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
  "Content-Type": "multipart/form-data",
};

// Utility function to replace \\ with \
// const replaceEscapedNewlines = async (data) => {
//   if (typeof data === "string") {
//     return data.replace(/\\\\n/g, "\\n");
//   }
//   if (Array.isArray(data)) {
//     return data.map((d) => replaceEscapedNewlines(d));
//   }
//   if (typeof data === "object" && data !== null) {
//     return Object.fromEntries(
//       Object.entries(data).map(([key, value]) => [key, replaceEscapedNewlines(value)])
//     );
//   }
//   console.log(data);
//   return data;
// };

async function updateMediaUrls(data) {
  if (!data) {
    return;
  }

  const dataKeys = Object.keys(data);
  for (const key of dataKeys) {
    const dataValue = data[key];

    if (typeof dataValue === "object") {
      // Recurse through & mutate
      await updateMediaUrls(dataValue);
      continue;
    }

    if (Array.isArray(dataValue)) {
      // Recurse through again, since we can perform Object.keys on arrays
      await updateMediaUrls(dataValue);
      continue;
    }

    if (typeof dataValue === "string") {
      // We can perform the URL switching
      // const prevValue = data[key];
      data[key] = dataValue.replaceAll("\\n", "\n");
    }
  }

  return data;
}

const postRequest = async (url, data = {}, headers = {}) => {
  return await axios.post(url, data, {
    mode: "cors",
    headers: { ...API_HEADERS, ...headers },
  });
};

const fetchGameFromAPI = async (param1) => {
  const gameLoginUrl = `https://api.collect-and-win.com/api/game/login/g-${param1}`;
  const gameLoginResponse = await postRequest(gameLoginUrl);

  if (gameLoginResponse.data.status !== "ok") throw new Error("Game login failed");

  localStorage.setItem("token", gameLoginResponse.data.data.token);

  const gameDataUrl = `https://api.collect-and-win.com/api/game/g-${param1}`;
  const gameDataResponse = await axios.get(gameDataUrl, {
    mode: "cors",
    headers: {
      ...API_HEADERS,
      TOKEN: gameLoginResponse.data.data.token,
    },
  });

  const token = gameLoginResponse.data.data.token;
  const processedData = await updateMediaUrls(gameDataResponse.data.data);
  return { processedData, token };
};

const fetchDataFromAPI = async (param1, param2) => {
  const loginUrl = `https://api.collect-and-win.com/api/game/login/m-${param1}/e-${param2}`;
  const loginResponse = await postRequest(loginUrl);

  if (loginResponse.data.status !== "ok") throw new Error("Login failed");

  const generateUrl = `https://api.collect-and-win.com/api/game/generate`;
  const generateResponse = await postRequest(
    generateUrl,
    { market_id: param1, event_id: param2 },
    { TOKEN: loginResponse.data.data.token }
  );

  if (generateResponse.data.status !== "ok") throw new Error("Game generation failed");

  localStorage.setItem(`${param1}-${param2}`, generateResponse.data.data.game);
  const game_id = generateResponse.data.data.game;
  const gameLoginUrl = `https://api.collect-and-win.com/api/game/login/g-${generateResponse.data.data.game}`;
  const gameLoginResponse = await postRequest(gameLoginUrl);

  if (gameLoginResponse.data.status !== "ok") throw new Error("Game login failed");

  localStorage.setItem("token", gameLoginResponse.data.data.token);
  const token = gameLoginResponse.data.data.token;

  const gameDataUrl = `https://api.collect-and-win.com/api/game/g-${generateResponse.data.data.game}`;
  const gameDataResponse = await axios.get(gameDataUrl, {
    mode: "cors",
    headers: {
      ...API_HEADERS,
      TOKEN: gameLoginResponse.data.data.token,
    },
  });

  localStorage.setItem("current_market", param1);

  const processedData = await updateMediaUrls(gameDataResponse.data.data);
  return { processedData, token, game_id };
};

const fetchFallbackData = async () => {
  const fetchResponse = await fetch("/data.json");
  const jsonData = await fetchResponse.json();
  const processedData = await updateMediaUrls(jsonData.data);
  return processedData;
};

const useStore = create((set, get) => ({
  data: {
    pages: [],
    pins: [],
    event: {},
    market: {},
    theme: {},
    game: [],
  },
  currentBackground: "",
  collectedItems: [],
  prizeData: null,
  loading: true,
  loaded: false,
  token: undefined,
  game_id: undefined,

  rateLimitError: false,
  eventNotStartedError: false,
  eventEndedError: false,
  initError: false,

  fetchData: async (param1, param2) => {
    const { loaded } = get();
    if (loaded) {
      return;
    }
    set({ loading: true, loaded: true });

    try {
      let jsonData;
      // const hostname = window.location.hostname;
      const saved_game_id = localStorage.getItem(`${param1}-${param2}`);
      const current_market = localStorage.getItem("current_market");

      if (getEnvironment() === "local") {
        jsonData = await fetchFallbackData();
        // getEnvironment() === "production" &&
      } else if (saved_game_id && current_market === param1) {
        const { processedData, token } = await fetchGameFromAPI(saved_game_id);
        jsonData = processedData;
        set({ token: token });
      } else if (param1 && param2) {
        const { processedData, token, game_id } = await fetchDataFromAPI(param1, param2);
        jsonData = processedData;
        set({ token: token, game_id: game_id });
      } else if (param1) {
        const { processedData, token, game_id } = await fetchGameFromAPI(param1);
        jsonData = processedData;
        set({ token: token, game_id: game_id });
      } else {
        set({ initError: true, loading: false, loaded: true });
      }
      if (jsonData) {
        console.log(jsonData);
        jsonData.pins.forEach((pin) => {
          if (pin.found) {
            set((state) => ({
              collectedItems: [...state.collectedItems, pin.identity],
            }));
          }
        });

        // DEVELOPMENT ONLY
        // Generate random pins around the user's location

        // Fetch the user's geolocation
        if (param1 === "demo" && param2 === "event" && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const currentLat = position.coords.latitude;
              const currentLng = position.coords.longitude;

              // Generate random points
              const randomPoints = generateRandomPoints(
                currentLat,
                currentLng,
                jsonData.pins.length,
                10,
                60
              );

              // Update pins with random points
              jsonData.pins = jsonData.pins.map((pin, index) => ({
                ...pin,
                geo_lat: randomPoints[index].lat,
                geo_lon: randomPoints[index].lng,
              }));

              console.log(position);
              console.log(jsonData);

              set({ data: jsonData, loading: false, loaded: true });
            },
            (error) => {
              console.error(error);
              set({ data: jsonData, loading: false, loaded: true });
            },
            {
              enableHighAccuracy: true, // Request high accuracy
              timeout: 10000, // Maximum time to try getting the location
              maximumAge: 0, // Do not accept a cached position
            }
          );
        } else {
          set({ data: jsonData, loading: false, loaded: true });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Rate limited
      if (
        (error?.response?.data?.messages?.[0] &&
          error.response.data.messages[0] === "Too many attempts") ||
        (error?.response?.data?.messages?.responseCode &&
          error.response.data.messages.responseCode === "too_many_attempts")
      ) {
        set({ rateLimitError: true, loading: false, loaded: true });
      }
      // Event not started
      else if (
        error?.response?.data?.messages?.responseCode &&
        error.response.data.messages.responseCode === "event_not_started"
      ) {
        set({ eventNotStartedError: true, loading: false, loaded: true });
      }
      // Event ended
      else if (
        error?.response?.data?.messages?.responseCode &&
        (error.response.data.messages.responseCode === "event_ended" ||
          error.response.data.messages.responseCode === "event_finished")
      ) {
        set({ eventEndedError: true, loading: false, loaded: true });
      }
      // Event not found / other
      else {
        set({ initError: true, loading: false, loaded: true });
      }
    }
  },
  addToCollectedItems: (itemId) =>
    set((state) => ({
      collectedItems: [...state.collectedItems, itemId],
    })),
  updateCurrentBackground: (background) =>
    set(() => ({
      currentBackground: background,
    })),
  updatePrizeData: (prizeData) =>
    set(() => ({
      prizeData: prizeData,
    })),
}));

// Generate random points within a specified range of the current location
const generateRandomPoints = (
  lat,
  lng,
  numPoints,
  minDistance,
  maxDistance,
  minSeparation = 20,
  maxRetries = 100 // Maximum number of retries to avoid infinite loops
) => {
  console.log("%c*** generateRandomPoints ***", "color: #c2003d");
  const points = [];
  const R = 6371e3; // Radius of the Earth in meters

  const haversineDistance = (lat1, lng1, lat2, lng2) => {
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLng = ((lng2 - lng1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
  };

  for (let i = 0; i < numPoints; i++) {
    let retries = 0;
    let pointAdded = false;
    let newLat, newLng; // Declare newLat and newLng outside the while loop
    while (!pointAdded && retries < maxRetries) {
      const randomDistance = Math.random() * (maxDistance - minDistance) + minDistance;
      const randomAngle = Math.random() * 2 * Math.PI;
      const deltaLat = ((randomDistance * Math.cos(randomAngle)) / R) * (180 / Math.PI);
      const deltaLng =
        ((randomDistance * Math.sin(randomAngle)) / (R * Math.cos((lat * Math.PI) / 180))) *
        (180 / Math.PI);

      newLat = lat + deltaLat; // Update newLat
      newLng = lng + deltaLng; // Update newLng

      let isFarEnough = true;
      for (const point of points) {
        if (haversineDistance(newLat, newLng, point.lat, point.lng) < minSeparation) {
          isFarEnough = false;
          break;
        }
      }

      if (isFarEnough) {
        points.push({ lat: newLat, lng: newLng });
        pointAdded = true;
      } else {
        retries++;
      }
    }

    if (!pointAdded && retries === maxRetries) {
      // Add the last generated point even if it doesn't meet the minSeparation requirement
      points.push({ lat: newLat, lng: newLng });
      console.log(`Added point after reaching maximum retries for point ${i + 1}.`);
    }
  }

  return points;
};

export const usePages = () => useStore((state) => state.data.market.json.pages);
export const usePage = (page) => useStore((state) => state.data.market.json.pages[page]);
export const useInfoPages = () => useStore((state) => state.data.pages);
export const useItems = () => useStore((state) => state.data.pins);
export const useEvent = () => useStore((state) => state.data.event);
export const useMarket = () => useStore((state) => state.data.market);
export const useTheme = () => useStore((state) => state.data && state.data.theme);
export const useLoading = () => useStore((state) => state.loading);
export const useLoaded = () => useStore((state) => state.loaded);
export const useRateLimitError = () => useStore((state) => state.rateLimitError);
export const useEventNotStartedError = () => useStore((state) => state.eventNotStartedError);
export const useEventEndedError = () => useStore((state) => state.eventEndedError);
export const useInitError = () => useStore((state) => state.initError);
export const useCollectedItems = () => useStore((state) => state.collectedItems);
export const useAddToCollectedItems = () => useStore((state) => state.addToCollectedItems);
export const useSetCurrentBackground = () => useStore((state) => state.updateCurrentBackground);
export const useBackground = () => useStore((state) => state.currentBackground);
export const usePrizeDsta = () => useStore((state) => state.prizeData);
export const useUpdatePrizeData = () => useStore((state) => state.updatePrizeData);
export const useGame = () => useStore((state) => state.data.game);
export const useGameId = () => useStore((state) => state.game_id);
export const useToken = () => useStore((state) => state.token);

export default useStore;
