import { useEffect, useState } from "react";

const useGeolocationAndOrientation = () => {
  const [geolocationSupported, setGeolocationSupported] = useState(null);
  const [orientationSupported, setOrientationSupported] = useState(null);

  useEffect(() => {
    const checkGeolocationSupport = () => {
      if (!navigator.geolocation) {
        setGeolocationSupported(false);
      } else {
        setGeolocationSupported(true);
      }
    };

    const checkOrientationSupport = () => {
      if (!window.DeviceOrientationEvent) {
        setOrientationSupported(false);
      } else {
        setOrientationSupported(true);
      }
    };

    checkGeolocationSupport();
    checkOrientationSupport();
  }, []);

  return { geolocationSupported, orientationSupported };
};

export default useGeolocationAndOrientation;
