// Button.js
import React, { forwardRef } from "react";
import styles from "./button.module.scss";
import { useNavigate } from "react-router-dom";

const Button = forwardRef(({ content, onClick }, ref) => {
  const navigate = useNavigate();
  const getTypeClass = () => {
    if (content.type === "border") {
      return styles.border;
    }
    return "";
  };

  return (
    <div
      ref={ref}
      className={`${styles.button} ${getTypeClass()} ${
        content.style === "large" && styles.large
      }`}
      style={{
        backgroundColor: content.backgroundColor,
        borderColor: content.borderColor,
        color: content.textColor,
        width: content.maxWidth,
      }}
      onClick={onClick ? onClick : navigate(content.href)}
    >
      {content.body}
    </div>
  );
});

export default Button;
