import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import styles from "./Underage.module.scss";
import { useEffect, useRef } from "react";
import { usePage, useTheme } from "../../store/store";
import Bubble from "../Bubble/Bubble";

const Underage = () => {
  const navigate = useNavigate();
  const page = usePage("Underage");
  const modalRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      gsap.to(modalRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: "power3.in",
        onComplete: () => navigate("/age"),
      });
    }, 5000);
  }, []);

  const bubbleContent = {
    type: "image",
    content: theme.bubbles_logo_image.url,
  };

  return (
    <>
      <div ref={modalRef} className={styles.wrapper}>
        <h1>{page.title}</h1>
        <p>{page.info}</p>
      </div>
      <div className={styles.bubbleWrapper}>
        <div className={styles.bubbleContainer}>
          <Bubble bubble={{ image: theme.bubbles_image.url, content: bubbleContent }} />
        </div>
      </div>
    </>
  );
};

export default Underage;
