function getBackgroundStyle(background) {
  let style = {};

  switch (background.type) {
    case "color":
      style.backgroundColor = background.value;
      break;
    case "gradient":
      style.backgroundImage = background.value;
      break;
    case "image":
      style.backgroundImage = `url(${background.value})`;
      break;
    default:
      console.warn("Unknown background type:", background.type);
  }

  return style;
}

export default getBackgroundStyle;
