/**
 * A custom React hook to detect the current environment.
 *
 * @returns {string} - The name of the environment ('local', 'staging', or 'production').
 *
 * @example
 * const environment = useEnvironmentDetection();
 * console.log(environment); // 'local', 'staging', or 'production'
 */

import { useState } from "react";

const useEnvironmentDetection = () => {
  const [environment] = useState(getEnvironment());
  return environment;
};

/**
 * Retrieves the current environment based on the window location hostname.
 *
 * @returns {string} - The name of the environment ('local', 'staging', or 'production').
 */

export const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (
    hostname.includes("localhost") ||
    hostname.includes("127.0.0.1") ||
    hostname.includes("192.168.")
  ) {
    return "local";
  } else if (hostname.includes("staging") || hostname.includes("vercel.app")) {
    return "staging";
  } else {
    return "production";
  }
};

export default useEnvironmentDetection;
