import { BrowserRouter, MemoryRouter, Route, Routes } from "react-router-dom";
import "../../css/styles.scss";
import useStore, {
  useRateLimitError,
  useEventNotStartedError,
  useEventEndedError,
  useInitError,
  useLoaded,
  useLoading,
} from "../../store/store";
import useEnvironmentDetection from "../../hooks/useEnvironmentDetection";
import Layout from "../../components/Layout/Layout";
import { AppProvider } from "../../providers/AppProvider";
import AgeScreener from "../../components/AgeScreener/AgeScreener";
import Intro from "../../components/Intro/Intro";
import Play from "../../components/Play/Play";
import Info from "../../components/Info/Info";
import Game from "../../components/Game/Game";
import InfoPage from "../../components/InfoPage/InfoPage";
import Win from "../../components/Win/Win";
import Underage from "../../components/Underage/Underage";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useEffect } from "react";
import Item from "../../components/Item/Item";
import { MapProvider } from "../../providers/MapProvider";
import CollectedItems from "../../components/CollectedItems/CollectedIrems";
import Instructions from "../../components/Instructions/Instructions";
import useWindowSize from "../../hooks/useWindowSize";
import styles from "./App.module.scss";
import errors from "./errors_de.json";
import Wysiwyg from "../../components/Wysiwyg/Wysiwyg";
// import RedirectWrapper from "../../components/RedirectWrapper/RedirectWrapper";

function App() {
  const { width, height } = useWindowSize();

  var pathArray = window.location.pathname.split("/");

  const market_id = pathArray[1];
  const event_id = pathArray[2];

  const rateLimitError = useRateLimitError();
  const eventNotStartedError = useEventNotStartedError();
  const eventEndedError = useEventEndedError();
  const initError = useInitError();

  useEffect(() => {
    localStorage.setItem("eventId", event_id);
    localStorage.setItem("market", market_id);
  }, [event_id, market_id]);

  useEffect(() => {
    window.document.documentElement.style.setProperty("--vh", `${height / 100}px`);
    window.document.documentElement.style.setProperty("--vh100", `${height}px`);
  }, [width, height]);

  const endpoint = useEnvironmentDetection();
  const Router = endpoint === "local" ? BrowserRouter : MemoryRouter;
  // const Router = MemoryRouter;

  const { fetchData } = useStore();
  const loading = useLoading();
  const loaded = useLoaded();

  useEffect(() => {
    if (!loaded) {
      fetchData(market_id, event_id);
    }
  }, [event_id, fetchData, loaded, market_id]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingIcon}>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    );
  }

  if (rateLimitError) {
    return (
      <div className={styles.wrapper}>
        <h1>{errors.rateLimitError.title}</h1>
        <Wysiwyg content={errors.rateLimitError.message} nl2br />
      </div>
    );
  }

  if (eventNotStartedError) {
    return (
      <div className={styles.wrapper}>
        <h1>{errors.eventNotStartedError.title}</h1>
        <Wysiwyg content={errors.eventNotStartedError.message} nl2br />
      </div>
    );
  }

  if (eventEndedError) {
    return (
      <div className={styles.wrapper}>
        <h1>{errors.eventEndedError.title}</h1>
        <Wysiwyg content={errors.eventEndedError.message} nl2br />
      </div>
    );
  }

  if (initError) {
    return (
      <div className={styles.wrapper}>
        <h1>{errors.initError.title}</h1>
        <Wysiwyg content={errors.initError.message} nl2br />
      </div>
    );
  }

  return (
    <AppProvider>
      <MapProvider>
        <Router>
          <div className="App">
            <Layout>
              {/* <RedirectWrapper> */}
              <Routes>
                <Route path="/age" exact element={<AgeScreener />} />
                <Route path="/" exact element={<Intro />} />
                <Route path="/instructions" exact element={<Instructions />} />
                <Route path="/play" exact element={<Play />} />
                <Route path="/info" exact element={<Info />} />
                <Route path="/info/:id" exact element={<InfoPage />} />
                <Route path="/item/:id" exact element={<Item />} />
                <Route path="/game" exact element={<Game />} />
                <Route path="/collected-items" exact element={<CollectedItems />} />
                <Route path="/win" exact element={<Win />} />
                <Route path="/underage" exact element={<Underage />} />
                <Route path="/error/:errorPage" exact element={<ErrorPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
              {/* </RedirectWrapper> */}
            </Layout>
          </div>
        </Router>
      </MapProvider>
    </AppProvider>
  );
}

export default App;
