import { useState } from "react";
import { useTheme } from "../../store/store";
import errors from "../../Pages/App/errors_de.json";
import styles from "./BrowserMessage.module.scss";

const BrowserMessage = () => {
  const theme = useTheme();

  const [isBrave] = useState(isBraveBrowser());

  if (isBrave) {
    return (
      <div className={styles.wrapper} style={{ color: theme.json.colour_secondary }}>
        <h1>{errors.browserError.title}</h1>
        <p>{errors.browserError.message}</p>
      </div>
    );
  }
};

function isBraveBrowser() {
  if (navigator.brave || navigator.userAgent.includes("Brave")) {
    return true;
  } else {
    return false;
  }
}

export default BrowserMessage;
