import React, { createContext, useContext, useRef } from "react";

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const markersRef = useRef({});

  const removeMarker = (productId) => {
    const marker = markersRef.current[productId];
    if (marker) {
      marker.remove();
      delete markersRef.current[productId];
    }
  };

  return (
    <MapContext.Provider value={{ markersRef, removeMarker }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => useContext(MapContext);
