/**
 * Converts new lines into HTML line breaks.
 *
 * @param {string} str - Original string.
 * @param {boolean} [isXhtml = false] - Whether to use XHTML or not (optional).
 * @returns {string} String with line breaks.
 */

export const nl2br = (str, isXhtml = false) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  var breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};
