// Note: you can also use the WpWysiwyg component to wrap
// formatted text inside an element tag with optional class name.

import parse from "html-react-parser";

import { nl2br as nl2brUtil } from "../../utils/nl2br";
import { prettyQuotes as prettyQuotesUtil } from "../../utils/prettyQuotes";
import { wpautop as wpautopUtil } from "../../utils/wpautop";

const Wysiwyg = ({ content, nl2br, prettyQuotes, wpautop, braceReplace = "text-accent" }) => {
  if (!content || typeof content !== "string") return null;

  // add text highlights
  content = content.replace(/\{\{/g, `<span className="${braceReplace}">`);
  content = content.replace(/\}\}/g, "</span>");

  // convert all line breaks to <br /> tags
  // we need to detect <br> in split text, so do this regardless of whether nl2br is specified
  if (nl2br) {
    content = nl2brUtil(content);
  }

  // replicate WordPress' wpautop function
  if (wpautop) {
    content = wpautopUtil(content);
  }

  // convert straight quotes to curly quotes
  if (prettyQuotes) {
    content = prettyQuotesUtil(content);
  }

  content = parse(content);

  return content;
};

export default Wysiwyg;
