/**
 * A custom React provider to handle application context
 *
 * @example
 *   const { isMenuHidden, setMenuHidden, isDiscalimerHidden, setDisclaimerHidden } = useAppContext();
 */

import React, { createContext, useContext, useRef, useState } from "react";

const AppContext = createContext();

export function AppProvider({ children }) {
  const [isMenuHidden, setMenuHidden] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showCloseProductButton, setShowCloseProductButton] = useState(false);
  const [arInitialised, setArInitialised] = useState(false);
  const ar = useRef();

  return (
    <AppContext.Provider
      value={{
        isMenuHidden,
        setMenuHidden,
        isMapExpanded,
        setIsMapExpanded,
        showCloseButton,
        setShowCloseButton,
        showBackButton,
        setShowBackButton,
        ar,
        showCloseProductButton,
        setShowCloseProductButton,
        arInitialised,
        setArInitialised,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
