import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Burger } from "../../icons/burger.svg";
import { ReactComponent as Finder } from "../../icons/finder.svg";
import { ReactComponent as Lozenge } from "../../icons/lozenge.svg";
import { ReactComponent as Cross } from "../../icons/close.svg";
import { ReactComponent as Back } from "../../icons/back.svg";
import { ReactComponent as Prize } from "../../icons/prize.svg";

import { useAppContext } from "../../providers/AppProvider";
import { useCollectedItems, useInfoPages, useItems, useTheme } from "../../store/store";
import styles from "./Navigation.module.scss";
import { useEffect, useRef, useState } from "react";
// import useEnvironmentDetection from "../../hooks/useEnvironmentDetection";

const Navigation = () => {
  const {
    isMenuHidden,
    showCloseButton,
    // setShowCloseButton,
    showBackButton,
    setShowBackButton,
    setMenuHidden,
    isMapExpanded,
    setIsMapExpanded,
    showCloseProductButton,
    setShowCloseProductButton,
    ar,
    arInitialised,
  } = useAppContext();

  const items = useItems();
  const collectedItems = useCollectedItems();
  // const endpoint = useEnvironmentDetection();

  const info = useInfoPages();
  const location = useLocation();
  const navRef = useRef(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [animateLoz, setAnimateLoz] = useState(false);
  const theme = useTheme();

  // console.log(info);

  const handleLozenge = () => {
    setTimeout(() => {
      setAnimateLoz(true);
    }, 300);
    setTimeout(() => {
      setAnimateLoz(false);
    }, 5000);
  };

  useEffect(() => {
    if (ar.current && arInitialised) {
      if (!location.pathname.includes("/game") || isMapExpanded) {
        ar.current.pause();
      } else {
        ar.current.resume();
      }
    }
  }, [ar, arInitialised, isMapExpanded, location.pathname]);

  useEffect(() => {
    const hiddenURLs = [
      "/intro",
      "/error",
      "age",
      "underage",
      "instructions",
      "offline",
      // "/collected-items",
    ];
    const isBaseURL = location.pathname === "/";

    if (isBaseURL || hiddenURLs.some((url) => location.pathname.includes(url))) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
  }, [location.pathname, setMenuHidden]);

  useEffect(() => {
    if (!location.pathname.includes("/item")) {
      setShowCloseProductButton(false);
    } else {
      setShowCloseProductButton(true);
    }
  }, [location.pathname, setShowCloseProductButton]);

  useEffect(() => {
    const infoIdPattern = /^\/info\/\w+$/;
    if (!infoIdPattern.test(location.pathname)) {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  }, [location.pathname, setShowBackButton]);

  useEffect(() => {
    // if (navRef.current) {
    const navLinkActive = document.querySelector(`.${styles.navLinkActive}`);
    if (navLinkActive) {
      setSelectedLink(navLinkActive);
    }
    // }
  }, [location, navRef]);

  useEffect(() => {
    if (selectedLink && navRef.current) {
      const scrollOptions = {
        left: selectedLink.offsetLeft - (navRef.current.offsetWidth - selectedLink.offsetWidth) / 2,
        behavior: "smooth",
      };
      navRef.current.scrollTo(scrollOptions);
    }
  }, [selectedLink]);

  if (isMenuHidden) {
    return null;
  }

  const handleCloseClick = () => {
    setTimeout(function () {
      setIsMapExpanded(!isMapExpanded);
    }, 100);
  };

  return (
    <div className={styles.nav} ref={navRef}>
      {showCloseProductButton && (
        <NavLink onClick={() => handleLozenge()} className={styles.navLinkActive} to={-1}>
          <Cross />
          Schließen
        </NavLink>
      )}
      {showCloseButton && isMapExpanded && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={styles.navLinkActive}
          onClick={() => handleCloseClick()}
          // onClick={() => setIsMapExpanded(!isMapExpanded)}
        >
          <Cross />
          Schließen
        </a>
      )}
      {!showCloseButton && !showCloseProductButton && (
        <>
          {info && info.length > 0 && (
            <NavLink
              className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}
              to={
                showBackButton
                  ? -1
                  : info && info.length === 1
                  ? `/info/${info[0].identity}`
                  : "/info"
              }
            >
              {showBackButton ? <Back /> : <Burger />}
              {theme.json.nav && theme.json.nav.info_title
                ? theme.json.nav.info_title
                : "Information"}
            </NavLink>
          )}
          <NavLink
            className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}
            to={"/game"}
          >
            {items.length === collectedItems.length ? <Prize /> : <Finder />}
            {items.length === collectedItems.length
              ? theme.json.nav && theme.json.nav.win_title
                ? theme.json.nav.win_title
                : "Mein Preis"
              : theme.json.nav && theme.json.nav.ar_title
              ? theme.json.nav.ar_title
              : "Vuse Finder"}
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}
            to={"/collected-items"}
          >
            <div
              className={clsx(styles.lozengeContainer, animateLoz && styles.lozengeContainerActive)}
            >
              <div className={styles.lozenges}>
                <div className={styles.lozengeAnim}></div>
                <Lozenge className={styles.lozenge} />
              </div>
              <span className={styles.collectedItemsCounter}>
                <span style={{ opacity: 1 }}>{collectedItems.length}</span>/{items.length}
              </span>
            </div>
            {theme.json.nav && theme.json.nav.collection_title
              ? theme.json.nav.collection_title
              : "Meine Sammlung"}
          </NavLink>
        </>
      )}
    </div>
  );
};

export default Navigation;
