import { useNavigate } from "react-router-dom";
import styles from "./Intro.module.scss";
import { usePage, useSetCurrentBackground, useTheme } from "../../store/store";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import useEnvironmentDetection from "../../hooks/useEnvironmentDetection";
import Bubble from "../Bubble/Bubble";

// No need to register useGSAP as it's not a GSAP plugin
// gsap.registerPlugin(useGSAP);

const Intro = () => {
  const navigate = useNavigate();
  const page = usePage("Intro");
  const setCurrentBackground = useSetCurrentBackground();
  const theme = useTheme();
  const bubbleRef = useRef(null);
  const environment = useEnvironmentDetection();

  const skipIntro = () => {
    navigate("/game");
  };

  useGSAP(
    () => {
      gsap.fromTo(
        bubbleRef.current,
        { y: window.innerHeight / 2 + 200 },
        {
          y: 0,
          duration: 3,
          ease: "power3.out",
          onComplete: () => navigate("/age"),
        }
      );
    },
    { dependencies: [bubbleRef] }
  );

  useEffect(() => {
    if (page) {
      setCurrentBackground(page.background);
    }
  }, [page, setCurrentBackground]);

  if (!page || !theme) {
    return null; // Make sure to return null if there's no content to display
  }

  const bubbleContent = {
    type: "image",
    content: theme.bubbles_logo_image.url,
  };

  const bubbleAnimationConfig = {
    y: 0,
    duration: 3,
    ease: "power3.out",
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bubbleContainer} ref={bubbleRef}>
        <Bubble
          bubble={{ image: theme.bubbles_image.url, content: bubbleContent }}
          animationConfig={bubbleAnimationConfig}
          animationStart={window.innerHeight / 2 + 200}
        />
      </div>
      {environment !== "production" && (
        <button onClick={skipIntro} className={styles.skip}>
          Skip Intro
        </button>
      )}
    </div>
  );
};

export default Intro;
