import { useNavigate } from "react-router-dom";
import styles from "./Play.module.scss";
import { useAppContext } from "../../providers/AppProvider";
import { usePage } from "../../store/store";

const Play = () => {
  const navigate = useNavigate();
  const { setMenuHidden } = useAppContext();
  const page = usePage("Play");

  return (
    <div className={styles.wrapper}>
      <span>{page.challengeTitle}</span>
      <h1>{page.challengeInfo}</h1>
      <p>{page.claimPrizeInfo}</p>
      <span>{page.goodLuck}</span>

      <button
        onClick={() => {
          navigate("/game");
        }}
      >
        {page.button}
      </button>
    </div>
  );
};

export default Play;
