import clsx from "clsx";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
// import styles from "./ScrollPrompt.module.scss";
const ScrollPrompt = ({ className }) => {
  const ref = useRef([]);
  const contRef = useRef([]);

  const timeline = gsap.timeline({ repeat: -1 });
  const duration = 0.5;

  useEffect(() => {
    const initialTimeline = gsap.timeline();
    initialTimeline.fromTo(
      contRef.current,
      { opacity: 0, bottom: "50%" },
      { opacity: 1, bottom: "10%", duration: 2, ease: "power3.out" }
    );
  }, []);

  useGSAP(
    () => {
      timeline
        .set(ref.current, {
          opacity: 0.2,
        })
        .fromTo(
          ref.current,
          { opacity: 0.2 },
          {
            opacity: 1,
            duration: duration,
            ease: "power3.in",
            stagger: 0.2,
          },
          0
        )
        .fromTo(
          ref.current,
          { opacity: 1 },
          {
            opacity: 0.2,
            duration: duration,
            ease: "power3.out",
            stagger: 0.2,
          },
          duration * 2
        );
    },
    { dependencies: [ref] }
  );

  return (
    <div ref={contRef} className={clsx("scroll-prompt", className && className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        x={0}
        y={0}
        style={{ enableBackground: "new 0 0 24 60" }}
        version="1.1"
        viewBox="0 0 24 60"
      >
        <path
          ref={(element) => {
            ref.current[0] = element;
          }}
          d="M.9 1.5v8.4L12 21 23.1 9.9V1.5L12 12.6z"
        />
        <path
          ref={(element) => {
            ref.current[1] = element;
          }}
          d="M.9 20.2v8.4L12 39.8l11.1-11.2v-8.4L12 31.4z"
        />
        <path
          ref={(element) => {
            ref.current[2] = element;
          }}
          d="M.9 39v8.4L12 58.5l11.1-11.1V39L12 50.1z"
        />
      </svg>
    </div>
  );
};

export default ScrollPrompt;
