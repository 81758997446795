import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import clsx from "clsx";

import { useAppContext } from "../../providers/AppProvider";
import {
  useItems,
  useCollectedItems,
  useAddToCollectedItems,
  useTheme,
  useSetCurrentBackground,
  useUpdatePrizeData,
  usePrizeDsta,
  // useGame,
  usePage,
  useGame,
  useToken,
  useGameId,
} from "../../store/store";
import { useMap } from "../../providers/MapProvider";
import useEnvironmentDetection from "../../hooks/useEnvironmentDetection";
import Button from "../Button/Button";
import Map from "../Map/Map";
import errors from "../../Pages/App/errors_de.json";

import styles from "./Game.module.scss";

const Game = () => {
  const { ar, arInitialised, setArInitialised } = useAppContext();
  const navigate = useNavigate();
  const items = useItems();
  const collectedItems = useCollectedItems();
  const addToCollectedItems = useAddToCollectedItems();
  const updatePrizeData = useUpdatePrizeData();
  const prizeData = usePrizeDsta();
  const { removeMarker } = useMap();
  const theme = useTheme();
  const setCurrentBackground = useSetCurrentBackground();
  const environment = useEnvironmentDetection();
  const page = usePage("Game");
  const game = useGame();
  const [displayARerror, setDisplayARerror] = useState(false);
  const [locationRequested, setLocationRequested] = useState(false);
  const recenterInterval = useRef();
  const token = useToken();
  const game_id = useGameId();
  const recenterTimeout = useRef();

  const eventId = localStorage.getItem("eventId");
  const marketId = localStorage.getItem("market");

  const points = (() => {
    const config = {
      ...theme.json.ar.config,
      bubble: `${window.location.origin}/${theme.json.ar.config.bubble}`,
    };

    const intro = theme.json.ar.intro;

    const click = theme.json.ar.click;

    const availableitems = items.filter((i) => !collectedItems.includes(i.identity));

    const points = availableitems.map((item) => ({
      name: item.identity,
      lat: parseFloat(item.geo_lat),
      lng: parseFloat(item.geo_lon),
      model: `${window.location.origin}/ar/${item.item_model_path}`,
      debug_model: `${window.location.origin}/ar/models/cube.glb`,
    }));

    const popup = {
      body: "AR requires access to device motion sensors",
      approve: "Continue",
      deny: "Cancel",
    };

    const click_size = 1;

    return {
      config,
      intro,
      click,
      click_size,
      points,
      popup,
    };
  })();

  // console.log(points);

  useEffect(() => {
    if (game.status === "won") {
      updatePrizeData({ code: game.prize_code, prize: game.prize_title });
    }
  }, [game.prize_code, game.prize_title, game.status, updatePrizeData]);

  const handleItemCollect = useCallback(
    async (itemId) => {
      const url = `https://api.collect-and-win.com/api/game/p-${itemId}`;
      const localToken = localStorage.getItem("token");
      const localGame_id = localStorage.getItem(`${marketId}-${eventId}`);

      console.log(token);
      console.log(game_id);
      navigate(`/item/${itemId}`);

      if (environment === "local") {
        removeMarker(itemId);
        addToCollectedItems(itemId);
        return;
      }
      try {
        const res = await axios.post(
          url,
          { game_id: localGame_id ? localGame_id : game.identity },
          {
            mode: "cors",
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
              "Content-Type": "multipart/form-data",
              TOKEN: localToken ? localToken : token,
            },
          }
        );

        if (res.data.status === "ok") {
          removeMarker(itemId);
          addToCollectedItems(itemId);
        }
      } catch (error) {
        console.log(error);
        console.error("Error collecting item:", error);
      }
    },
    [addToCollectedItems, navigate, removeMarker, environment]
  );

  const recenterAr = useCallback(() => {
    if (ar.current && arInitialised) {
      try {
        ar.current.recenterCamera();
        console.log("%c--- recenterAr ---", "color: #b20065");
      } catch (e) {
        console.error("Error recentering AR:", e);
      }
    }
  }, [ar, arInitialised]);

  // Using a ref to avoid a closure in the setInterval
  const recenterArRef = useRef(recenterAr);

  useEffect(() => {
    recenterArRef.current = recenterAr;
  }, [recenterAr]);

  useEffect(() => {
    recenterInterval.current = setInterval(() => {
      recenterArRef.current();
    }, 8000);

    return () => {
      clearInterval(recenterInterval.current);
    };
  }, [ar, arInitialised]);

  useEffect(() => {
    if (ar.current && arInitialised) {
      const startRecenterTimeout = () => {
        clearInterval(recenterInterval.current);
        recenterTimeout.current = setTimeout(() => {
          recenterInterval.current = setInterval(() => {
            recenterArRef.current();
          }, 8000);
        }, 20000);
      };

      ar.current.addEventListener("ar_bubble_triggered", startRecenterTimeout);
    }
  }, []);

  useEffect(() => {
    if (!ar.current && !arInitialised) {
      if (!locationRequested) {
        setLocationRequested(true);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              initAR();
            },
            (error) => {
              showLocationError();
            },
            {
              enableHighAccuracy: true, // Request high accuracy
              timeout: 10000, // Maximum time to try getting the location
              maximumAge: 0, // Do not accept a cached position
            }
          );
        } else {
          showLocationError();
        }
      }
    }
  }, [ar, arInitialised, handleItemCollect, points, setArInitialised, theme.json, environment]);

  const showLocationError = () => {
    navigate("/error/location");
  };

  const initAR = useCallback(() => {
    const onInit = () => {
      console.log("ar is running");
      setArInitialised(true);
    };

    const onGetDevice = (evt) => {
      console.log("User got device: " + evt.detail.id);
      if (recenterTimeout.current) {
        clearTimeout(recenterTimeout.current);
      }
      if (recenterTimeout.current) {
        clearInterval(recenterTimeout.current);
      }
      recenterInterval.current = setInterval(() => {
        recenterArRef.current();
      }, 8000);
      handleItemCollect(evt.detail.id);
    };

    const flagError = (e) => {
      theme.json.default_background(e);
      alert(e);
      setDisplayARerror(true);
    };

    const hiddenBubbles = false;

    ar.current = window.initAR(environment !== "production" ? true : false);

    ar.current.addEventListener("ar_initialised", onInit);
    ar.current.addEventListener("ar_device_get", onGetDevice);
    ar.current.addEventListener("ar_error", flagError);
    ar.current.init(points, hiddenBubbles);
  }, [ar, arInitialised, handleItemCollect, points, setArInitialised, theme.json, environment]);

  useEffect(() => {
    if (ar.current && arInitialised) {
      ar.current.resume();
    }
  }, [ar, arInitialised]);

  useEffect(() => {
    console.log(page);
    setCurrentBackground(page.background);
  }, [page.background, setCurrentBackground]);

  useEffect(() => {
    if (collectedItems.length === items.length && !prizeData && game.status !== "won") {
      const getPrize = async () => {
        const url = `https://api.collect-and-win.com/api/game/play`;
        const localToken = localStorage.getItem("token");
        const localGame_id = localStorage.getItem(`${marketId}-${eventId}`);
        try {
          const res = await axios.post(
            url,
            { identity: localGame_id ? localGame_id : game.identity },
            {
              mode: "cors",
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                Accept: "application/json",
                "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
                "Content-Type": "multipart/form-data",
                TOKEN: localToken ? localToken : token,
              },
            }
          );

          if (res.data.status === "ok") {
            console.log("Prize data received:", res.data.data);
            updatePrizeData(res.data.data);
          } else {
            console.log("Prize data status not ok:", res.data);
          }
        } catch (error) {
          console.error("Error fetching prize data:", error);
          updatePrizeData({
            error: true,
            title: page.error.title,
            message: page.error.message,
          });
        }
      };

      if (environment === "local") {
        updatePrizeData({
          code: 1234,
          prize: "1x free drink",
        });
      } else {
        getPrize();
      }
    }
  }, [collectedItems, environment, items.length, prizeData, updatePrizeData]);

  useEffect(() => {
    console.log("Prize data:", prizeData);
  }, [prizeData]);

  if (displayARerror) {
    navigate("/error/ar");
  }

  if (prizeData) {
    if (prizeData.error) {
      return (
        <div className={styles.wrapperError}>
          <h1>{prizeData.title}</h1>
          <p>{prizeData.message}</p>
          <Link to={page.buttonLink}>{page.buttonText}</Link>
        </div>
      );
    }

    return (
      <div className={styles.wrapperFull}>
        {page.title && (
          <h1>
            <span>{page.title}</span> {prizeData.prize}
          </h1>
        )}
        {page.body && <p>{page.body}</p>}
        <div style={{ height: "auto", margin: "0 auto", maxWidth: "34%", width: "100%" }}>
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            bgColor={"#fff"}
            fgColor={"#000"}
            value={prizeData.code || ""}
          />
        </div>
        <h3>{prizeData.code}</h3>
        {page.buttonText && page.buttonLink && (
          <Button
            onClick={() => window.open(page.buttonLink, "_blank")}
            content={{
              type: "fill",
              style: "large",
              body: page.buttonText,
              backgroundColor: theme.json.colour_secondary,
              borderColor: theme.json.colour_secondary,
              textColor: theme.json.colour_primary,
            }}
          />
        )}
      </div>
    );
  }

  // Developer buttons for collecting items and recentering AR manually

  if (arInitialised || environment === "local") {
    return (
      <>
        {environment !== "production" && (
          <div className={styles.itemContainer}>
            {items.map((item) => (
              <button
                key={item.identity}
                className={clsx(styles.item, {
                  [styles.itemCollected]: collectedItems.includes(item.identity),
                })}
                onClick={() => handleItemCollect(item.identity)}
                disabled={collectedItems && collectedItems.includes(item.identity)}
              >
                {item.title}
                <br />
                {collectedItems && collectedItems.includes(item.identity) ? "Collected" : "Collect"}
              </button>
            ))}
            <button className={styles.itemRecenter} onClick={recenterAr}>
              Recenter AR
            </button>
          </div>
        )}
        <Map />
      </>
    );
  }
};

export default Game;
