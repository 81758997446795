import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import styles from "./Instructions.module.scss";
import { useItems, usePage, useSetCurrentBackground, useTheme } from "../../store/store";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import CustomEase from "gsap/CustomEase";
import Bubble from "../Bubble/Bubble";
import Button from "../Button/Button";
import useWindowSize from "../../hooks/useWindowSize";
import useEnvironmentDetection from "../../hooks/useEnvironmentDetection";
// import { getRandomArbitrary } from "../Utils/random";
import { getRandomArbitrary } from "../../utils/random";
gsap.registerPlugin(CustomEase);

const Instructions = () => {
  const page = usePage("Instructions");
  const setCurrentBackground = useSetCurrentBackground();
  const theme = useTheme();
  const bubbleRefs = useRef([]);
  const previewBubbleRefs = useRef([]);
  const buttonRef = useRef();
  const items = useItems();
  const environment = useEnvironmentDetection();
  const { height } = useWindowSize();
  // const [stageHeight, setStageHeight] = useState(null);

  const navigate = useNavigate();

  useGSAP(
    () => {
      if (bubbleRefs.current.length > 0) {
        const timeline = gsap.timeline();
        const bubbleHeight = bubbleRefs.current[0].clientHeight;
        const centerY = window.innerHeight / 2 - bubbleHeight / 2;
        const animationDuration = 3.5; // Duration for each bubble animation
        const animationLastDuration = animationDuration * 0.75; // Duration for each bubble animation
        const overlapTime = animationDuration * 0.33; // Overlap time between animations

        // Animate the first bubble from the center of the screen to off the top
        timeline.fromTo(
          bubbleRefs.current[0],
          { top: centerY - 26 },
          {
            top: -bubbleHeight,
            duration: animationDuration,
            ease: "power3.inOut",
          }
        );

        // Animate the remaining bubbles sequentially from the bottom to the top with reduced delay
        bubbleRefs.current.slice(1).forEach((bubble, index, array) => {
          const isLastBubble = index === array.length - 1;

          const overlap = () => {
            if (isLastBubble) {
              return 3;
            } else if (index === 0) {
              return 1.5;
            }
            return overlapTime;
          };

          timeline.fromTo(
            bubble,
            { top: window.innerHeight },
            {
              top: isLastBubble ? centerY - 50 : -bubbleHeight,
              duration: isLastBubble ? animationLastDuration : animationDuration,
              ease: isLastBubble
                ? "back.out(1.15)"
                : CustomEase.create(
                    "custom",
                    "M0,0 C0,0.207 0.195,0.451 0.5,0.5 0.802,0.547 1,0.802 1,1 "
                  ),
            },
            `-=${isLastBubble ? 0 : overlap()}` // Overlap the animations by the defined overlap time
          );
          // Some scaling up, but the timings aren't right yet,
          // will hopefully get this fixed at some point
          // .fromTo(
          //   bubble,
          //   { scale: 0.8 },
          //   {
          //     scale: 1,
          //     duration: isLastBubble
          //       ? animationLastDuration
          //       : animationDuration,
          //     ease: "power4.out",
          //   },
          //   `-=${isLastBubble ? 0 : overlap()}` // Overlap the animations by the defined overlap time
          // );
        });

        // Animate the button
        timeline.fromTo(
          buttonRef.current,
          { opacity: 0, y: centerY },
          {
            opacity: 1,
            y: centerY,
            duration: animationDuration,
            ease: "power3.out",
          }
        );
      }
    },
    { dependencies: [bubbleRefs] }
  );

  useGSAP(
    () => {
      if (previewBubbleRefs.current.length > 0 && height) {
        const bubbleHeight = bubbleRefs.current[0].clientHeight;

        const overlapTime = 1.55; // Overlap time between animations

        // Animate the remaining bubbles sequentially from the bottom to the top with reduced delay
        previewBubbleRefs.current.slice(1).forEach((bubble, index, array) => {
          const animationDuration = getRandomArbitrary(14, 9);
          const timeline = gsap.timeline({
            repeat: -1,
            delay: index - overlapTime,
          });
          const randXStart = getRandomArbitrary(bubbleHeight * 0.4, bubbleHeight * -0.4);
          const randXEnd = getRandomArbitrary(bubbleHeight * 0.4, bubbleHeight * -0.4);
          const randScale = getRandomArbitrary(1, 0.6);
          const startY = (height + bubbleHeight) * -1;

          timeline
            .fromTo(
              bubble,
              { y: 0 },
              {
                y: startY,
                duration: animationDuration,
                ease: `power${getRandomArbitrary(2, 4, true)}.out`,
              },
              0
            )
            .to(
              bubble,
              {
                scale: randScale,
                duration: animationDuration,
                ease: `power${getRandomArbitrary(2, 4, true)}.out`,
              },
              0
            )
            .fromTo(
              bubble,
              {
                x: randXStart,
              },
              {
                x: randXEnd,
                duration: animationDuration * getRandomArbitrary(0.7, 1),
                ease: `power${getRandomArbitrary(2, 4, true)}.out`,
              },
              0
            );
          // .fromTo(
          //   bubble,
          //   {
          //     x: randX,
          //   },
          //   {
          //     x: randEndX,
          //     duration: animationDuration / 2,
          //     ease: `power${getRandomArbitrary(2, 4, true)}.out`,
          //   },
          //   animationDuration / 2
          // );
        });
      }
    },
    { dependencies: [items, previewBubbleRefs, height] }
  );

  useEffect(() => {
    if (page) {
      setCurrentBackground(page.background);
    }
  }, [page, setCurrentBackground]);

  const skipIntro = () => {
    navigate("/game");
  };

  const starGameContent = {
    type: "fill",
    href: "#", // This is not used anymore since we handle routing in onClick
    body: page.button,
    backgroundColor: theme.json.colour_secondary,
    borderColor: theme.json.colour_secondary,
    textColor: theme.json.colour_primary,
    maxWidth: "180px",
    style: "large",
  };

  return (
    <div className={styles.wrapper}>
      {page.bubbles &&
        page.bubbles.map((bubble, index) => (
          <div
            key={index}
            className={styles.bubbleContainer}
            ref={(el) => (bubbleRefs.current[index] = el)}
          >
            <Bubble bubble={{ image: theme.bubbles_image.url, content: bubble }} />
          </div>
        ))}
      {items.length > 0 &&
        items.map((previewBubble, index) => {
          if (previewBubble.item_preview) {
            // const min = -30;
            // const max = 30;
            return (
              <div
                ref={(el) => (previewBubbleRefs.current[index] = el)}
                key={index}
                className={clsx(
                  styles.previewBubbleContainer,
                  (index + 1) % 2 === 1 ? styles.previewBubbleLeft : styles.previewBubbleRight
                )}
              >
                <Bubble
                  bubble={{
                    image: theme.bubbles_image.url,
                    content: {
                      type: "image",
                      content: previewBubble.item_preview.url,
                    },
                  }}
                />
              </div>
            );
          }
          return null;
        })}
      <Button ref={buttonRef} content={starGameContent} onClick={() => navigate("/game")} />
      {environment !== "production" && (
        <button onClick={skipIntro} className={styles.skip}>
          Skip Intro
        </button>
      )}
    </div>
  );
};

export default Instructions;
