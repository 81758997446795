/**
 * Replace straight quotes with curly quotes.
 *
 * @param {string} string - String to format.
 * @returns {string} Formatted string.
 */

export const prettyQuotes = (string) => {
  // Replace straight double quotes with curly quotes
  string = string.replace(/(^|\W)"(\S)/g, '$1\u201c$2');
  string = string.replace(/(\S)"(\W|$)/g, '$1\u201d$2');
  string = string.replace(/([^0-9])"/g, '$1\u201d');
  string = string.replace(/"/g, '\u201c');

  // Replace straight single quotes with curly quotes
  string = string.replace(/(^|\W)'(\S)/g, '$1\u2018$2');
  string = string.replace(/(\S)'(\W|$)/g, '$1\u2019$2');
  string = string.replace(/([^0-9])'/g, '$1\u2019');
  string = string.replace(/'/g, '\u2018');

  return string;
};
