// import { useAppContext } from "../../providers/AppProvider";
import { useBackground, useCollectedItems, useItems, useTheme } from "../../store/store";
import getBackgroundStyle from "../../utils/getBackgroundStyle";
import Navigation from "../Navigation/Navigation";
import OfflineMessage from "../OfflineMessage/OfflineMessage";
import OrientationMessage from "../OrientationMessage/OrientationMessage";
import BrowserMessage from "../BrowserMessage/BrowserMessage";
import styles from "./Layout.module.scss";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const background = useBackground();
  const theme = useTheme();
  const location = useLocation();
  // const { isMenuHidden } = useAppContext();

  const style = getBackgroundStyle(background);
  const items = useItems();
  const collectedItems = useCollectedItems();

  return (
    <div
      className={styles.wrapper}
      style={{
        fontFamily: theme.json.font_family,
        color: theme.json.colour_primary,
        ...style,
      }}
    >
      <div className={styles.grid}>
        <div>
          <Navigation />
        </div>
        <div className={styles.middle}>
          <div
            id="pokemon_ui"
            style={{
              zIndex:
                location.pathname.includes("/game") && collectedItems.length !== items.length
                  ? 1
                  : -1,
            }}
            className={styles.ar}
            // className={`${styles.ar} ${!isMenuHidden && styles.arShrunk}`}
          >
            <canvas id="pokemon"></canvas>
          </div>
          <div
            style={style}
            className={styles.content}
            // className={`${styles.content} ${!isMenuHidden && styles.contentShrunk}`}
          >
            {children}
          </div>
        </div>
        {theme.json.disclaimer && <div className={styles.disclaimer}>{theme.json.disclaimer}</div>}
      </div>

      <OrientationMessage />
      <OfflineMessage />
      <BrowserMessage />
    </div>
  );
};

export default Layout;
